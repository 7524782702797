<script setup>
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    default: () => [],
  },
  activeTab: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: 'auto',
  },
})

const emits = defineEmits(['change'])

function tabClass(tab) {
  if (tab === props.activeTab)
    return 'active-tab'
  else
    return props.disabled ? 'inactive-disabled-tab' : 'inactive-tab'
}
</script>

<template>
  <div v-if="tabs.length" id="tab-wrapper" class="bg-white w-full md:w-auto rounded p-2 flex flex-col md:flex-row md:items-center text-[#8B8B8B] font-semibold text-sm">
    <div v-for="(tab, index) in tabs" :key="index" class="flex items-center text-center">
      <div
        class="w-full p-3 rounded md:w-auto"
        :class="tabClass(tab)"
        :style="{ width: props.width }"
        @click="disabled || tab === props.activeTab ? {} : emits('change', tab)"
      >
        {{ tab }}
      </div>
      <div v-if="index < tabs.length - 1" class="text-[#D1D1D1] px-2 hidden md:block">
        |
      </div>
    </div>
  </div>
</template>

<style>
.active-tab {
  @apply bg-[#ECF6FF] text-[#4782FC] cursor-pointer
}

.inactive-tab {
  @apply cursor-pointer
}

.inactive-disabled-tab {
  @apply cursor-not-allowed text-[#B9B9B9]
}
</style>
